.wrapper {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  padding: var(--gutter-large);
  height: 100%;
  pointer-events: none;
}

.container {
  position: relative;
}

.notification {
  display: flex;
  align-items: center;
  gap: var(--gutter-regular);
  position: absolute;
  top: 0;
  right: 0;
  width: 320px;
  pointer-events: all;
  padding: calc(var(--gutter-medium) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  line-height: 1.5em;
  border-radius: var(--border-radius-regular);
  box-shadow: var(--shadow-medium);
  animation: notification var(--transition);
  transition: top var(--transition);
}

.notificationEnter {
  opacity: 0;
  transform: translateX(100%);
}

.notificationEnterActive {
  opacity: 1;
  transform: none;
  transition: top var(--transition), opacity var(--transition), transform var(--transition);
}

.notificationExit {
  opacity: 1;
  transform: none;
}

.notificationExitActive {
  opacity: 0;
  transform: translateX(100%);
  transition: top var(--transition), opacity var(--transition), transform var(--transition);
}