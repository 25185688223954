.container {
  cursor: var(--file-input-cursor, text);
  display: flex;
  flex-direction: column;
  gap: var(--gutter-xsmall);
}

.label {
  color: var(--color-text-secondary);
  padding-left: var(--gutter-small);
}

.field {
  position: relative;
  display: flex;
  align-items: center;
  height: var(--file-input-field-height, 40px);
  padding: 0 calc(var(--gutter-regular) - 1px);
  gap: calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
  transition: color var(--transition), border-color var(--transition);
}

.field:hover, .field:focus-within {
  border-color: var(--color-border-accent);
}

.prefix, .suffix {
  color: var(--color-text-secondary);
  transition: color var(--transition);
}

.field:hover .prefix, .field:hover .suffix,
.field:focus-within .prefix, .field:focus-within .suffix {
  color: var(--color-text-accent);
}

.divider {
  height: var(--gutter-regular);
  width: 1px;
  border-radius: 1px;
  background-color: var(--color-border-secondary);
}

.placeholder {
  text-align: center;
  flex: 1;
}

.input {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: var(--file-input-cursor, pointer);
  width: 100%;
  height: 100%;
}

.multiline .input {
  width: 100%;
  height: auto;
  padding: calc(var(--gutter-regular) - 1px);
}

.input::placeholder {
  color: var(--color-text-disabled)
}

.disabled .label {
  color: var(--color-text-disabled)
}

.disabled .field {
  cursor: auto;
  color: var(--color-text-disabled);
  border: 1px dashed var(--color-border-disabled);
  box-shadow: none;
}

.disabled .field .prefix, .disabled .field .suffix {
  color: var(--color-text-disabled) !important;
}

.disabled .input {
  cursor: auto;
  color: var(--color-text-disabled)
}