.container {
  cursor: var(--text-input-cursor, text);
  display: flex;
  flex-direction: column;
  gap: var(--gutter-xsmall);
}

.label {
  color: var(--color-text-secondary);
  padding-left: var(--gutter-small);
}

.field {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 calc(var(--gutter-regular) - 1px);
  gap: calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
  transition: color var(--transition), border-color var(--transition);
}

.multiline .field {
  padding: 0;
  gap: 0;
  height: auto;
}

.field:hover,
.field:focus-within {
  border-color: var(--color-border-accent);
}

.prefix,
.suffix {
  color: var(--color-text-secondary);
  transition: color var(--transition);
}

.field:hover .prefix,
.field:hover .suffix,
.field:focus-within .prefix,
.field:focus-within .suffix {
  color: var(--color-text-accent);
}

.divider {
  height: var(--gutter-regular);
  width: 1px;
  border-radius: 1px;
  background-color: var(--color-border-secondary);
}

.input {
  cursor: var(--text-input-cursor, text);
  flex: 1;
  width: 0px;
  height: 100%;
}

.multiline .input {
  width: 100%;
  height: auto;
  resize: vertical;
  padding: calc(var(--gutter-regular) - 1px);
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-transition: 'color 9999s ease-out, background-color 9999s ease-out';
  -webkit-transition-delay: 9999s;
}

.input::placeholder {
  color: var(--color-text-disabled);
}

.disabled .label {
  color: var(--color-text-disabled);
}

.disabled .field {
  cursor: auto;
  color: var(--color-text-disabled);
  border: 1px dashed var(--color-border-disabled);
  box-shadow: none;
}

.disabled .field .prefix,
.disabled .field .suffix {
  color: var(--color-text-disabled) !important;
}

.disabled .input {
  cursor: auto;
  color: var(--color-text-disabled);
}
