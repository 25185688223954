.feed {
  position: relative;
  width: 100%;
}

.card {
  padding: calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
}

.feed_list {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-regular);
}
