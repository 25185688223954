@import-normalize;

:root {
  --color-source-black: 0, 0, 0;
  --color-black: rgba(0, 0, 0);
  --color-source-white: 255, 255, 255;
  --color-white: rgba(255, 255, 255);
  --color-source-slate-50: 248, 250, 252;
  --color-slate-50: rgba(248, 250, 252);
  --color-source-slate-100: 241, 245, 249;
  --color-slate-100: rgba(241, 245, 249);
  --color-source-slate-200: 226, 232, 240;
  --color-slate-200: rgba(226, 232, 240);
  --color-source-slate-300: 203, 213, 225;
  --color-slate-300: rgba(203, 213, 225);
  --color-source-slate-400: 148, 163, 184;
  --color-slate-400: rgba(148, 163, 184);
  --color-source-slate-500: 100, 116, 139;
  --color-slate-500: rgba(100, 116, 139);
  --color-source-slate-600: 71, 85, 105;
  --color-slate-600: rgba(71, 85, 105);
  --color-source-slate-700: 51, 65, 85;
  --color-slate-700: rgba(51, 65, 85);
  --color-source-slate-800: 30, 41, 59;
  --color-slate-800: rgba(30, 41, 59);
  --color-source-slate-900: 15, 23, 42;
  --color-slate-900: rgba(15, 23, 42);
  --color-source-slate-950: 2, 6, 23;
  --color-slate-950: rgba(2, 6, 23);
  --color-source-gray-50: 249, 250, 251;
  --color-gray-50: rgba(249, 250, 251);
  --color-source-gray-100: 243, 244, 246;
  --color-gray-100: rgba(243, 244, 246);
  --color-source-gray-200: 229, 231, 235;
  --color-gray-200: rgba(229, 231, 235);
  --color-source-gray-300: 209, 213, 219;
  --color-gray-300: rgba(209, 213, 219);
  --color-source-gray-400: 156, 163, 175;
  --color-gray-400: rgba(156, 163, 175);
  --color-source-gray-500: 107, 114, 128;
  --color-gray-500: rgba(107, 114, 128);
  --color-source-gray-600: 75, 85, 99;
  --color-gray-600: rgba(75, 85, 99);
  --color-source-gray-700: 55, 65, 81;
  --color-gray-700: rgba(55, 65, 81);
  --color-source-gray-800: 31, 41, 55;
  --color-gray-800: rgba(31, 41, 55);
  --color-source-gray-900: 17, 24, 39;
  --color-gray-900: rgba(17, 24, 39);
  --color-source-gray-950: 3, 7, 18;
  --color-gray-950: rgba(3, 7, 18);
  --color-source-zinc-50: 250, 250, 250;
  --color-zinc-50: rgba(250, 250, 250);
  --color-source-zinc-100: 244, 244, 245;
  --color-zinc-100: rgba(244, 244, 245);
  --color-source-zinc-200: 228, 228, 231;
  --color-zinc-200: rgba(228, 228, 231);
  --color-source-zinc-300: 212, 212, 216;
  --color-zinc-300: rgba(212, 212, 216);
  --color-source-zinc-400: 161, 161, 170;
  --color-zinc-400: rgba(161, 161, 170);
  --color-source-zinc-500: 113, 113, 122;
  --color-zinc-500: rgba(113, 113, 122);
  --color-source-zinc-600: 82, 82, 91;
  --color-zinc-600: rgba(82, 82, 91);
  --color-source-zinc-700: 63, 63, 70;
  --color-zinc-700: rgba(63, 63, 70);
  --color-source-zinc-800: 39, 39, 42;
  --color-zinc-800: rgba(39, 39, 42);
  --color-source-zinc-900: 24, 24, 27;
  --color-zinc-900: rgba(24, 24, 27);
  --color-source-zinc-950: 9, 9, 11;
  --color-zinc-950: rgba(9, 9, 11);
  --color-source-neutral-50: 250, 250, 250;
  --color-neutral-50: rgba(250, 250, 250);
  --color-source-neutral-100: 245, 245, 245;
  --color-neutral-100: rgba(245, 245, 245);
  --color-source-neutral-200: 229, 229, 229;
  --color-neutral-200: rgba(229, 229, 229);
  --color-source-neutral-300: 212, 212, 212;
  --color-neutral-300: rgba(212, 212, 212);
  --color-source-neutral-400: 163, 163, 163;
  --color-neutral-400: rgba(163, 163, 163);
  --color-source-neutral-500: 115, 115, 115;
  --color-neutral-500: rgba(115, 115, 115);
  --color-source-neutral-600: 82, 82, 82;
  --color-neutral-600: rgba(82, 82, 82);
  --color-source-neutral-700: 64, 64, 64;
  --color-neutral-700: rgba(64, 64, 64);
  --color-source-neutral-800: 38, 38, 38;
  --color-neutral-800: rgba(38, 38, 38);
  --color-source-neutral-900: 23, 23, 23;
  --color-neutral-900: rgba(23, 23, 23);
  --color-source-neutral-950: 10, 10, 10;
  --color-neutral-950: rgba(10, 10, 10);
  --color-source-stone-50: 250, 250, 249;
  --color-stone-50: rgba(250, 250, 249);
  --color-source-stone-100: 245, 245, 244;
  --color-stone-100: rgba(245, 245, 244);
  --color-source-stone-200: 231, 229, 228;
  --color-stone-200: rgba(231, 229, 228);
  --color-source-stone-300: 214, 211, 209;
  --color-stone-300: rgba(214, 211, 209);
  --color-source-stone-400: 168, 162, 158;
  --color-stone-400: rgba(168, 162, 158);
  --color-source-stone-500: 120, 113, 108;
  --color-stone-500: rgba(120, 113, 108);
  --color-source-stone-600: 87, 83, 78;
  --color-stone-600: rgba(87, 83, 78);
  --color-source-stone-700: 68, 64, 60;
  --color-stone-700: rgba(68, 64, 60);
  --color-source-stone-800: 41, 37, 36;
  --color-stone-800: rgba(41, 37, 36);
  --color-source-stone-900: 28, 25, 23;
  --color-stone-900: rgba(28, 25, 23);
  --color-source-stone-950: 12, 10, 9;
  --color-stone-950: rgba(12, 10, 9);
  --color-source-red-50: 254, 242, 242;
  --color-red-50: rgba(254, 242, 242);
  --color-source-red-100: 254, 226, 226;
  --color-red-100: rgba(254, 226, 226);
  --color-source-red-200: 254, 202, 202;
  --color-red-200: rgba(254, 202, 202);
  --color-source-red-300: 252, 165, 165;
  --color-red-300: rgba(252, 165, 165);
  --color-source-red-400: 248, 113, 113;
  --color-red-400: rgba(248, 113, 113);
  --color-source-red-500: 239, 68, 68;
  --color-red-500: rgba(239, 68, 68);
  --color-source-red-600: 220, 38, 38;
  --color-red-600: rgba(220, 38, 38);
  --color-source-red-700: 185, 28, 28;
  --color-red-700: rgba(185, 28, 28);
  --color-source-red-800: 153, 27, 27;
  --color-red-800: rgba(153, 27, 27);
  --color-source-red-900: 127, 29, 29;
  --color-red-900: rgba(127, 29, 29);
  --color-source-red-950: 69, 10, 10;
  --color-red-950: rgba(69, 10, 10);
  --color-source-orange-50: 255, 247, 237;
  --color-orange-50: rgba(255, 247, 237);
  --color-source-orange-100: 255, 237, 213;
  --color-orange-100: rgba(255, 237, 213);
  --color-source-orange-200: 254, 215, 170;
  --color-orange-200: rgba(254, 215, 170);
  --color-source-orange-300: 253, 186, 116;
  --color-orange-300: rgba(253, 186, 116);
  --color-source-orange-400: 251, 146, 60;
  --color-orange-400: rgba(251, 146, 60);
  --color-source-orange-500: 249, 115, 22;
  --color-orange-500: rgba(249, 115, 22);
  --color-source-orange-600: 234, 88, 12;
  --color-orange-600: rgba(234, 88, 12);
  --color-source-orange-700: 194, 65, 12;
  --color-orange-700: rgba(194, 65, 12);
  --color-source-orange-800: 154, 52, 18;
  --color-orange-800: rgba(154, 52, 18);
  --color-source-orange-900: 124, 45, 18;
  --color-orange-900: rgba(124, 45, 18);
  --color-source-orange-950: 67, 20, 7;
  --color-orange-950: rgba(67, 20, 7);
  --color-source-amber-50: 255, 251, 235;
  --color-amber-50: rgba(255, 251, 235);
  --color-source-amber-100: 254, 243, 199;
  --color-amber-100: rgba(254, 243, 199);
  --color-source-amber-200: 253, 230, 138;
  --color-amber-200: rgba(253, 230, 138);
  --color-source-amber-300: 252, 211, 77;
  --color-amber-300: rgba(252, 211, 77);
  --color-source-amber-400: 251, 191, 36;
  --color-amber-400: rgba(251, 191, 36);
  --color-source-amber-500: 245, 158, 11;
  --color-amber-500: rgba(245, 158, 11);
  --color-source-amber-600: 217, 119, 6;
  --color-amber-600: rgba(217, 119, 6);
  --color-source-amber-700: 180, 83, 9;
  --color-amber-700: rgba(180, 83, 9);
  --color-source-amber-800: 146, 64, 14;
  --color-amber-800: rgba(146, 64, 14);
  --color-source-amber-900: 120, 53, 15;
  --color-amber-900: rgba(120, 53, 15);
  --color-source-amber-950: 69, 26, 3;
  --color-amber-950: rgba(69, 26, 3);
  --color-source-yellow-50: 254, 252, 232;
  --color-yellow-50: rgba(254, 252, 232);
  --color-source-yellow-100: 254, 249, 195;
  --color-yellow-100: rgba(254, 249, 195);
  --color-source-yellow-200: 254, 240, 138;
  --color-yellow-200: rgba(254, 240, 138);
  --color-source-yellow-300: 253, 224, 71;
  --color-yellow-300: rgba(253, 224, 71);
  --color-source-yellow-400: 250, 204, 21;
  --color-yellow-400: rgba(250, 204, 21);
  --color-source-yellow-500: 234, 179, 8;
  --color-yellow-500: rgba(234, 179, 8);
  --color-source-yellow-600: 202, 138, 4;
  --color-yellow-600: rgba(202, 138, 4);
  --color-source-yellow-700: 161, 98, 7;
  --color-yellow-700: rgba(161, 98, 7);
  --color-source-yellow-800: 133, 77, 14;
  --color-yellow-800: rgba(133, 77, 14);
  --color-source-yellow-900: 113, 63, 18;
  --color-yellow-900: rgba(113, 63, 18);
  --color-source-yellow-950: 66, 32, 6;
  --color-yellow-950: rgba(66, 32, 6);
  --color-source-lime-50: 247, 254, 231;
  --color-lime-50: rgba(247, 254, 231);
  --color-source-lime-100: 236, 252, 203;
  --color-lime-100: rgba(236, 252, 203);
  --color-source-lime-200: 217, 249, 157;
  --color-lime-200: rgba(217, 249, 157);
  --color-source-lime-300: 190, 242, 100;
  --color-lime-300: rgba(190, 242, 100);
  --color-source-lime-400: 163, 230, 53;
  --color-lime-400: rgba(163, 230, 53);
  --color-source-lime-500: 132, 204, 22;
  --color-lime-500: rgba(132, 204, 22);
  --color-source-lime-600: 101, 163, 13;
  --color-lime-600: rgba(101, 163, 13);
  --color-source-lime-700: 77, 124, 15;
  --color-lime-700: rgba(77, 124, 15);
  --color-source-lime-800: 63, 98, 18;
  --color-lime-800: rgba(63, 98, 18);
  --color-source-lime-900: 54, 83, 20;
  --color-lime-900: rgba(54, 83, 20);
  --color-source-lime-950: 26, 46, 5;
  --color-lime-950: rgba(26, 46, 5);
  --color-source-green-50: 240, 253, 244;
  --color-green-50: rgba(240, 253, 244);
  --color-source-green-100: 220, 252, 231;
  --color-green-100: rgba(220, 252, 231);
  --color-source-green-200: 187, 247, 208;
  --color-green-200: rgba(187, 247, 208);
  --color-source-green-300: 134, 239, 172;
  --color-green-300: rgba(134, 239, 172);
  --color-source-green-400: 74, 222, 128;
  --color-green-400: rgba(74, 222, 128);
  --color-source-green-500: 34, 197, 94;
  --color-green-500: rgba(34, 197, 94);
  --color-source-green-600: 22, 163, 74;
  --color-green-600: rgba(22, 163, 74);
  --color-source-green-700: 21, 128, 61;
  --color-green-700: rgba(21, 128, 61);
  --color-source-green-800: 22, 101, 52;
  --color-green-800: rgba(22, 101, 52);
  --color-source-green-900: 20, 83, 45;
  --color-green-900: rgba(20, 83, 45);
  --color-source-green-950: 5, 46, 22;
  --color-green-950: rgba(5, 46, 22);
  --color-source-emerald-50: 236, 253, 245;
  --color-emerald-50: rgba(236, 253, 245);
  --color-source-emerald-100: 209, 250, 229;
  --color-emerald-100: rgba(209, 250, 229);
  --color-source-emerald-200: 167, 243, 208;
  --color-emerald-200: rgba(167, 243, 208);
  --color-source-emerald-300: 110, 231, 183;
  --color-emerald-300: rgba(110, 231, 183);
  --color-source-emerald-400: 52, 211, 153;
  --color-emerald-400: rgba(52, 211, 153);
  --color-source-emerald-500: 16, 185, 129;
  --color-emerald-500: rgba(16, 185, 129);
  --color-source-emerald-600: 5, 150, 105;
  --color-emerald-600: rgba(5, 150, 105);
  --color-source-emerald-700: 4, 120, 87;
  --color-emerald-700: rgba(4, 120, 87);
  --color-source-emerald-800: 6, 95, 70;
  --color-emerald-800: rgba(6, 95, 70);
  --color-source-emerald-900: 6, 78, 59;
  --color-emerald-900: rgba(6, 78, 59);
  --color-source-emerald-950: 2, 44, 34;
  --color-emerald-950: rgba(2, 44, 34);
  --color-source-teal-50: 240, 253, 250;
  --color-teal-50: rgba(240, 253, 250);
  --color-source-teal-100: 204, 251, 241;
  --color-teal-100: rgba(204, 251, 241);
  --color-source-teal-200: 153, 246, 228;
  --color-teal-200: rgba(153, 246, 228);
  --color-source-teal-300: 94, 234, 212;
  --color-teal-300: rgba(94, 234, 212);
  --color-source-teal-400: 45, 212, 191;
  --color-teal-400: rgba(45, 212, 191);
  --color-source-teal-500: 20, 184, 166;
  --color-teal-500: rgba(20, 184, 166);
  --color-source-teal-600: 13, 148, 136;
  --color-teal-600: rgba(13, 148, 136);
  --color-source-teal-700: 15, 118, 110;
  --color-teal-700: rgba(15, 118, 110);
  --color-source-teal-800: 17, 94, 89;
  --color-teal-800: rgba(17, 94, 89);
  --color-source-teal-900: 19, 78, 74;
  --color-teal-900: rgba(19, 78, 74);
  --color-source-teal-950: 4, 47, 46;
  --color-teal-950: rgba(4, 47, 46);
  --color-source-cyan-50: 236, 254, 255;
  --color-cyan-50: rgba(236, 254, 255);
  --color-source-cyan-100: 207, 250, 254;
  --color-cyan-100: rgba(207, 250, 254);
  --color-source-cyan-200: 165, 243, 252;
  --color-cyan-200: rgba(165, 243, 252);
  --color-source-cyan-300: 103, 232, 249;
  --color-cyan-300: rgba(103, 232, 249);
  --color-source-cyan-400: 34, 211, 238;
  --color-cyan-400: rgba(34, 211, 238);
  --color-source-cyan-500: 6, 182, 212;
  --color-cyan-500: rgba(6, 182, 212);
  --color-source-cyan-600: 8, 145, 178;
  --color-cyan-600: rgba(8, 145, 178);
  --color-source-cyan-700: 14, 116, 144;
  --color-cyan-700: rgba(14, 116, 144);
  --color-source-cyan-800: 21, 94, 117;
  --color-cyan-800: rgba(21, 94, 117);
  --color-source-cyan-900: 22, 78, 99;
  --color-cyan-900: rgba(22, 78, 99);
  --color-source-cyan-950: 8, 51, 68;
  --color-cyan-950: rgba(8, 51, 68);
  --color-source-sky-50: 240, 249, 255;
  --color-sky-50: rgba(240, 249, 255);
  --color-source-sky-100: 224, 242, 254;
  --color-sky-100: rgba(224, 242, 254);
  --color-source-sky-200: 186, 230, 253;
  --color-sky-200: rgba(186, 230, 253);
  --color-source-sky-300: 125, 211, 252;
  --color-sky-300: rgba(125, 211, 252);
  --color-source-sky-400: 56, 189, 248;
  --color-sky-400: rgba(56, 189, 248);
  --color-source-sky-500: 14, 165, 233;
  --color-sky-500: rgba(14, 165, 233);
  --color-source-sky-600: 2, 132, 199;
  --color-sky-600: rgba(2, 132, 199);
  --color-source-sky-700: 3, 105, 161;
  --color-sky-700: rgba(3, 105, 161);
  --color-source-sky-800: 7, 89, 133;
  --color-sky-800: rgba(7, 89, 133);
  --color-source-sky-900: 12, 74, 110;
  --color-sky-900: rgba(12, 74, 110);
  --color-source-sky-950: 8, 47, 73;
  --color-sky-950: rgba(8, 47, 73);
  --color-source-blue-50: 239, 246, 255;
  --color-blue-50: rgba(239, 246, 255);
  --color-source-blue-100: 219, 234, 254;
  --color-blue-100: rgba(219, 234, 254);
  --color-source-blue-200: 191, 219, 254;
  --color-blue-200: rgba(191, 219, 254);
  --color-source-blue-300: 147, 197, 253;
  --color-blue-300: rgba(147, 197, 253);
  --color-source-blue-400: 96, 165, 250;
  --color-blue-400: rgba(96, 165, 250);
  --color-source-blue-500: 59, 130, 246;
  --color-blue-500: rgba(59, 130, 246);
  --color-source-blue-600: 37, 99, 235;
  --color-blue-600: rgba(37, 99, 235);
  --color-source-blue-700: 29, 78, 216;
  --color-blue-700: rgba(29, 78, 216);
  --color-source-blue-800: 30, 64, 175;
  --color-blue-800: rgba(30, 64, 175);
  --color-source-blue-900: 30, 58, 138;
  --color-blue-900: rgba(30, 58, 138);
  --color-source-blue-950: 23, 37, 84;
  --color-blue-950: rgba(23, 37, 84);
  --color-source-indigo-50: 238, 242, 255;
  --color-indigo-50: rgba(238, 242, 255);
  --color-source-indigo-100: 224, 231, 255;
  --color-indigo-100: rgba(224, 231, 255);
  --color-source-indigo-200: 199, 210, 254;
  --color-indigo-200: rgba(199, 210, 254);
  --color-source-indigo-300: 165, 180, 252;
  --color-indigo-300: rgba(165, 180, 252);
  --color-source-indigo-400: 129, 140, 248;
  --color-indigo-400: rgba(129, 140, 248);
  --color-source-indigo-500: 99, 102, 241;
  --color-indigo-500: rgba(99, 102, 241);
  --color-source-indigo-600: 79, 70, 229;
  --color-indigo-600: rgba(79, 70, 229);
  --color-source-indigo-700: 67, 56, 202;
  --color-indigo-700: rgba(67, 56, 202);
  --color-source-indigo-800: 55, 48, 163;
  --color-indigo-800: rgba(55, 48, 163);
  --color-source-indigo-900: 49, 46, 129;
  --color-indigo-900: rgba(49, 46, 129);
  --color-source-indigo-950: 30, 27, 75;
  --color-indigo-950: rgba(30, 27, 75);
  --color-source-violet-50: 245, 243, 255;
  --color-violet-50: rgba(245, 243, 255);
  --color-source-violet-100: 237, 233, 254;
  --color-violet-100: rgba(237, 233, 254);
  --color-source-violet-200: 221, 214, 254;
  --color-violet-200: rgba(221, 214, 254);
  --color-source-violet-300: 196, 181, 253;
  --color-violet-300: rgba(196, 181, 253);
  --color-source-violet-400: 167, 139, 250;
  --color-violet-400: rgba(167, 139, 250);
  --color-source-violet-500: 139, 92, 246;
  --color-violet-500: rgba(139, 92, 246);
  --color-source-violet-600: 124, 58, 237;
  --color-violet-600: rgba(124, 58, 237);
  --color-source-violet-700: 109, 40, 217;
  --color-violet-700: rgba(109, 40, 217);
  --color-source-violet-800: 91, 33, 182;
  --color-violet-800: rgba(91, 33, 182);
  --color-source-violet-900: 76, 29, 149;
  --color-violet-900: rgba(76, 29, 149);
  --color-source-violet-950: 46, 16, 101;
  --color-violet-950: rgba(46, 16, 101);
  --color-source-purple-50: 250, 245, 255;
  --color-purple-50: rgba(250, 245, 255);
  --color-source-purple-100: 243, 232, 255;
  --color-purple-100: rgba(243, 232, 255);
  --color-source-purple-200: 233, 213, 255;
  --color-purple-200: rgba(233, 213, 255);
  --color-source-purple-300: 216, 180, 254;
  --color-purple-300: rgba(216, 180, 254);
  --color-source-purple-400: 192, 132, 252;
  --color-purple-400: rgba(192, 132, 252);
  --color-source-purple-500: 168, 85, 247;
  --color-purple-500: rgba(168, 85, 247);
  --color-source-purple-600: 147, 51, 234;
  --color-purple-600: rgba(147, 51, 234);
  --color-source-purple-700: 126, 34, 206;
  --color-purple-700: rgba(126, 34, 206);
  --color-source-purple-800: 107, 33, 168;
  --color-purple-800: rgba(107, 33, 168);
  --color-source-purple-900: 88, 28, 135;
  --color-purple-900: rgba(88, 28, 135);
  --color-source-purple-950: 59, 7, 100;
  --color-purple-950: rgba(59, 7, 100);
  --color-source-fuchsia-50: 253, 244, 255;
  --color-fuchsia-50: rgba(253, 244, 255);
  --color-source-fuchsia-100: 250, 232, 255;
  --color-fuchsia-100: rgba(250, 232, 255);
  --color-source-fuchsia-200: 245, 208, 254;
  --color-fuchsia-200: rgba(245, 208, 254);
  --color-source-fuchsia-300: 240, 171, 252;
  --color-fuchsia-300: rgba(240, 171, 252);
  --color-source-fuchsia-400: 232, 121, 249;
  --color-fuchsia-400: rgba(232, 121, 249);
  --color-source-fuchsia-500: 217, 70, 239;
  --color-fuchsia-500: rgba(217, 70, 239);
  --color-source-fuchsia-600: 192, 38, 211;
  --color-fuchsia-600: rgba(192, 38, 211);
  --color-source-fuchsia-700: 162, 28, 175;
  --color-fuchsia-700: rgba(162, 28, 175);
  --color-source-fuchsia-800: 134, 25, 143;
  --color-fuchsia-800: rgba(134, 25, 143);
  --color-source-fuchsia-900: 112, 26, 117;
  --color-fuchsia-900: rgba(112, 26, 117);
  --color-source-fuchsia-950: 74, 4, 78;
  --color-fuchsia-950: rgba(74, 4, 78);
  --color-source-pink-50: 253, 242, 248;
  --color-pink-50: rgba(253, 242, 248);
  --color-source-pink-100: 252, 231, 243;
  --color-pink-100: rgba(252, 231, 243);
  --color-source-pink-200: 251, 207, 232;
  --color-pink-200: rgba(251, 207, 232);
  --color-source-pink-300: 249, 168, 212;
  --color-pink-300: rgba(249, 168, 212);
  --color-source-pink-400: 244, 114, 182;
  --color-pink-400: rgba(244, 114, 182);
  --color-source-pink-500: 236, 72, 153;
  --color-pink-500: rgba(236, 72, 153);
  --color-source-pink-600: 219, 39, 119;
  --color-pink-600: rgba(219, 39, 119);
  --color-source-pink-700: 190, 24, 93;
  --color-pink-700: rgba(190, 24, 93);
  --color-source-pink-800: 157, 23, 77;
  --color-pink-800: rgba(157, 23, 77);
  --color-source-pink-900: 131, 24, 67;
  --color-pink-900: rgba(131, 24, 67);
  --color-source-pink-950: 80, 7, 36;
  --color-pink-950: rgba(80, 7, 36);
  --color-source-rose-50: 255, 241, 242;
  --color-rose-50: rgba(255, 241, 242);
  --color-source-rose-100: 255, 228, 230;
  --color-rose-100: rgba(255, 228, 230);
  --color-source-rose-200: 254, 205, 211;
  --color-rose-200: rgba(254, 205, 211);
  --color-source-rose-300: 253, 164, 175;
  --color-rose-300: rgba(253, 164, 175);
  --color-source-rose-400: 251, 113, 133;
  --color-rose-400: rgba(251, 113, 133);
  --color-source-rose-500: 244, 63, 94;
  --color-rose-500: rgba(244, 63, 94);
  --color-source-rose-600: 225, 29, 72;
  --color-rose-600: rgba(225, 29, 72);
  --color-source-rose-700: 190, 18, 60;
  --color-rose-700: rgba(190, 18, 60);
  --color-source-rose-800: 159, 18, 57;
  --color-rose-800: rgba(159, 18, 57);
  --color-source-rose-900: 136, 19, 55;
  --color-rose-900: rgba(136, 19, 55);
  --color-source-rose-950: 76, 5, 25;
  --color-rose-950: rgba(76, 5, 25);
}

:root {
  --color-source-base-50: var(--color-source-zinc-50);
  --color-base-50: var(--color-zinc-50);
  --color-source-base-100: var(--color-source-zinc-100);
  --color-base-100: var(--color-zinc-100);
  --color-source-base-200: var(--color-source-zinc-200);
  --color-base-200: var(--color-zinc-200);
  --color-source-base-300: var(--color-source-zinc-300);
  --color-base-300: var(--color-zinc-300);
  --color-source-base-400: var(--color-source-zinc-400);
  --color-base-400: var(--color-zinc-400);
  --color-source-base-500: var(--color-source-zinc-500);
  --color-base-500: var(--color-zinc-500);
  --color-source-base-600: var(--color-source-zinc-600);
  --color-base-600: var(--color-zinc-600);
  --color-source-base-700: var(--color-source-zinc-700);
  --color-base-700: var(--color-zinc-700);
  --color-source-base-800: var(--color-source-zinc-800);
  --color-base-800: var(--color-zinc-800);
  --color-source-base-900: var(--color-source-zinc-900);
  --color-base-900: var(--color-zinc-900);
  --color-source-base-950: var(--color-source-zinc-950);
  --color-base-950: var(--color-zinc-950);

  --color-source-accent-50: var(--color-source-indigo-50);
  --color-accent-50: var(--color-indigo-50);
  --color-source-accent-100: var(--color-source-indigo-100);
  --color-accent-100: var(--color-indigo-100);
  --color-source-accent-200: var(--color-source-indigo-200);
  --color-accent-200: var(--color-indigo-200);
  --color-source-accent-300: var(--color-source-indigo-300);
  --color-accent-300: var(--color-indigo-300);
  --color-source-accent-400: var(--color-source-indigo-400);
  --color-accent-400: var(--color-indigo-400);
  --color-source-accent-500: var(--color-source-indigo-500);
  --color-accent-500: var(--color-indigo-500);
  --color-source-accent-600: var(--color-source-indigo-600);
  --color-accent-600: var(--color-indigo-600);
  --color-source-accent-700: var(--color-source-indigo-700);
  --color-accent-700: var(--color-indigo-700);
  --color-source-accent-800: var(--color-source-indigo-800);
  --color-accent-800: var(--color-indigo-800);
  --color-source-accent-900: var(--color-source-indigo-900);
  --color-accent-900: var(--color-indigo-900);
  --color-source-accent-950: var(--color-source-indigo-950);
  --color-accent-950: var(--color-indigo-950);
}

:root {
  --dark-color-text-primary: var(--color-base-100);
  --dark-color-text-secondary: var(--color-base-400);
  --dark-color-text-disabled: var(--color-base-500);
  --dark-color-text-button: var(--color-base-100);
  --dark-color-text-accent: var(--color-accent-400);

  --dark-color-background-primary: var(--color-base-900);
  --dark-color-background-secondary: var(--color-base-950);
  --dark-color-background-overlay: rgba(var(--color-source-base-950), 0.75);
  --dark-color-background-disabled: var(--color-base-950);
  --dark-color-background-accent: var(--color-accent-500);
  --dark-color-background-accent-light: rgba(var(--color-source-accent-300), 0.25);
  --dark-color-background-accent-lighter: rgba(var(--color-source-accent-500), 0.15);

  --dark-color-border-primary: var(--color-base-700);
  --dark-color-border-secondary: var(--color-base-800);
  --dark-color-border-disabled: var(--color-base-800);
  --dark-color-border-accent: var(--color-accent-500);
  --dark-color-border-accent-light: var(--color-accent-300);
  --dark-color-border-accent-lighter: rgba(var(--color-source-accent-200), 0.15);

  --dark-color-shadow-regular: rgba(var(--color-source-base-300), 0.2);
  --dark-color-shadow-regular-accent: rgba(var(--color-source-accent-700), 0.1);

  --light-color-text-primary: var(--color-base-800);
  --light-color-text-secondary: var(--color-base-600);
  --light-color-text-disabled: var(--color-base-500);
  --light-color-text-button: white;
  --light-color-text-accent: var(--color-accent-500);

  --light-color-background-primary: white;
  --light-color-background-secondary: var(--color-base-50);
  --light-color-background-overlay: rgba(var(--color-source-base-100), 0.75);
  --light-color-background-disabled: var(--color-base-100);
  --light-color-background-accent: var(--color-accent-500);
  --light-color-background-accent-light: var(--color-accent-100);
  --light-color-background-accent-lighter: rgba(var(--color-source-accent-100), 0.25);

  --light-color-border-primary: var(--color-base-200);
  --light-color-border-secondary: var(--color-base-100);
  --light-color-border-disabled: var(--color-base-100);
  --light-color-border-accent: var(--color-accent-400);
  --light-color-border-accent-light: var(--color-accent-300);
  --light-color-border-accent-lighter: rgba(var(--color-source-accent-100), 0.5);

  --light-color-shadow-regular: rgba(var(--color-source-base-500), 0.1);
  --light-color-shadow-regular-accent: rgba(var(--color-source-accent-700), 0.1);
}

body {
  --font-family: 'Nunito Sans', sans-serif;
  --font-family-logo: 'Montserrat', sans-serif;

  --font-size-large: 20px;
  --font-size-medium: 16px;
  --font-size-regular: 13px;
  --font-size-small: 12px;

  --font-weight-regular: 600;
  --font-weight-medium: 700;

  --icon-size-regular: 16px;

  --gutter-xsmall: 4px;
  --gutter-small: 8px;
  --gutter-regular: 16px;
  --gutter-medium: 24px;
  --gutter-large: 36px;

  --border-radius-regular: 5px;
  --border-radius-small: 3px;

  --shadow-regular: 0px 1px 2px 0px var(--color-shadow-regular), 0px 4px 8px 0px var(--color-shadow-regular);
  --shadow-medium: var(--shadow-regular), 0px 8px 16px 0px var(--color-shadow-regular);
  --shadow-filter-regular: drop-shadow(0px 1px 2px var(--color-shadow-regular))
    drop-shadow(0px 4px 8px var(--color-shadow-regular));
  --shadow-filter-medium: var(--shadow-filter-regular) drop-shadow(0px 8px 16px var(--color-shadow-regular));
  --shadow-regular-accent: 0px 1px 2px 0px var(--color-shadow-regular-accent),
    0px 4px 8px 0px var(--color-shadow-regular-accent);
  --shadow-filter-regular-accent: drop-shadow(0px 1px 2px var(--color-shadow-regular-accent))
    drop-shadow(0px 4px 8px var(--color-shadow-regular-accent));

  --transition-easing: cubic-bezier(0.23, 1, 0.32, 1);
  --transition-duration: 300ms;
  --transition: var(--transition-duration) var(--transition-easing);

  color: var(--color-text-primary);
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);

  background-color: var(--color-background-secondary);

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}

body {
  --color-text-primary: var(--light-color-text-primary);
  --color-text-secondary: var(--light-color-text-secondary);
  --color-text-disabled: var(--light-color-text-disabled);
  --color-text-button: var(--light-color-text-button);
  --color-text-accent: var(--light-color-text-accent);

  --color-background-primary: var(--light-color-background-primary);
  --color-background-secondary: var(--light-color-background-secondary);
  --color-background-overlay: var(--light-color-background-overlay);
  --color-background-disabled: var(--light-color-background-disabled);
  --color-background-accent: var(--light-color-background-accent);
  --color-background-accent-light: var(--light-color-background-accent-light);
  --color-background-accent-lighter: var(--light-color-background-accent-lighter);

  --color-border-primary: var(--light-color-border-primary);
  --color-border-secondary: var(--light-color-border-secondary);
  --color-border-disabled: var(--light-color-border-disabled);
  --color-border-accent: var(--light-color-border-accent);
  --color-border-accent-light: var(--light-color-border-accent-light);
  --color-border-accent-lighter: var(--light-color-border-accent-lighter);

  --color-shadow-regular: var(--light-color-shadow-regular);
  --color-shadow-regular-accent: var(--light-color-shadow-regular-accent);
}

input,
button,
textarea {
  margin: 0px;
  padding: 0px;
  background: none;
  border: none;
  outline: none;

  color: var(--color-text-primary);
  font-family: var(--font-family);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-regular);
}

a {
  color: currentColor;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

::selection {
  color: var(--color-text-primary);
  background-color: var(--color-background-accent-light);
}
