.button {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: calc(var(--gutter-regular) - 1px);
  white-space: nowrap;
  user-select: none;
  overflow: hidden;

  --spinner-size: 16px;
}

.button:not(.icon) {
  min-width: 120px;
}

.button.loading {
  pointer-events: none;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  height: var(--gutter-regular);
  width: 1px;
  border-radius: 1px;
}

.default {
  height: 40px;
  padding: 0 calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
  transition: color var(--transition), border-color var(--transition);
}

.default:hover, .default:focus {
  color: var(--color-text-accent);
  border-color: var(--border-color-text-accent);
}

.default .divider {
  background-color: var(--color-border-secondary);
}

.primary {
  height: 40px;
  padding: 0 calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-accent);
  border: 1px solid var(--color-border-accent);
  border-radius: var(--border-radius-regular);
  box-shadow: var(--shadow-regular-accent);
  color: var(--color-text-button);
}

.primary .divider {
  background-color: var(--color-border-accent);
}

.default:disabled, .primary:disabled {
  cursor: auto;
  color: var(--color-text-disabled);
  border: 1px dashed var(--color-border-disabled);
  box-shadow: none;
}

.text {
  height: 32px;
  padding: var(--gutter-small);
  color: var(--color-text-secondary);
  transition: color var(--transition);
}

.text:hover, .text:focus {
  color: var(--color-text-accent);
}