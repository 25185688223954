.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
  padding: var(--gutter-large);
  background-color: var(--color-background-overlay);
  transition: opacity calc(var(--transition-duration) * 2) var(--transition-easing);
}

.container.visible {
  opacity: 1;
  pointer-events: all;
}

.dialog {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--modal-max-width, 480px);
  gap: calc(var(--gutter-large));
  padding: var(--gutter-large);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  box-shadow: var(--shadow-medium);
  transform: scale(0);
  transition: transform calc(var(--transition-duration) * 2) var(--transition-easing);
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0;
    align-items: flex-end !important;
  }

  .dialog {
    max-width: 100%;
    gap: calc(var(--gutter-regular));
    padding: var(--gutter-regular);
    transform: scale(1) translateY(100%);
    transform-origin: bottom !important;
  }

  .body {
    gap: calc(var(--gutter-regular));
  }
}

.container.visible .dialog {
  transform: none;
}

.head {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-medium);
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: calc(var(--gutter-regular));
}
