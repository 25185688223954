.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  width: 100%;
  height: 100%;
  padding: 64px;
  padding-bottom: max(env(safe-area-inset-bottom), 32px);
  overflow-y: auto;
  background-image: url('../../../../public/img/about/cover_2.png');
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .container {
    gap: var(--gutter-regular);
    padding: var(--gutter-regular);
  }
}

@media screen and (max-width: 1140px) {
  .container {
    justify-content: flex-start;
  }
}

.container .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--gutter-regular);
}

.container .heading .title {
  font-family: var(--font-family-logo);
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  background: linear-gradient(270deg, #b275ff 0%, #8e9ff8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.container .heading .subtitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  color: var(--color-text-secondary);
  text-align: center;
  max-width: 564px;
}

.container .content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid var(--color-border-primary);
  border-radius: 16px;
  padding-right: var(--gutter-regular);
  max-width: 960px;
}

@media screen and (max-width: 1140px) {
  .container .content {
    grid-template-columns: 1fr;
    padding-right: 0;
    max-width: 100%;
    width: 100%;
    padding-bottom: var(--gutter-regular);
  }
}

.container .content .item {
  display: flex;
  flex-direction: column;
  padding: var(--gutter-regular);
}

@media screen and (max-width: 1140px) {
  .container .content .item {
    width: 100%;
  }
}

.container .content .item.highlighted {
  background: #6967ce;
  background-image: url('../../../../public/img/pricing.png');
  background-size: cover;
  background-position: center;
  border: 1px solid #a5b3f9;
  box-shadow: 6px 12px 32px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  transform: translateY(calc(var(--gutter-regular) * -1));
  margin-left: var(--gutter-regular);
}

@media screen and (max-width: 1140px) {
  .container .content .item.highlighted {
    transform: none;
    margin-left: var(--gutter-regular);
    margin-right: var(--gutter-regular);
    width: calc(100% - var(--gutter-regular) * 2);
  }
}

.container .content .item .title {
  font-weight: 700;
  font-size: var(--gutter-medium);
  color: var(--color-text-primary);
  margin-bottom: var(--gutter-regular);
}

.container .content .item.highlighted .title {
  color: var(--dark-color-text-primary);

}

.container .content .item .list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 80px;
}

.container .content .item .list .listItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-text-secondary);
}

.container .content .item .list .listItem .icon {
  margin-top: 2px;
}

.container .content .item.highlighted .list .listItem {
  color: var(--dark-color-text-primary);
}

.container .content .item .price {
  font-size: 16px;
  color: var(--color-text-secondary);
  margin-top: auto;
  margin-bottom: var(--gutter-regular);
}

.container .content .item.highlighted .price {
  color: var(--dark-color-text-primary);
}

.container .content .item .price span {
  font-weight: 700;
  font-size: var(--gutter-regular);
  color: var(--color-text-secondary);
}

.container .content .item.highlighted .price span {
  color: var(--dark-color-text-primary);
}