.wrapper {
  display: grid;
  gap: 64px;
  overflow-y: auto;
  height: 100%;
}

.hero {
  width: 100%;
  padding: 24px 0;
  background-size: cover;
  background-position: center;
}

.hero .group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero .logo {
  width: 100%;
  max-width: 256px;
}

.hero .title {
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  .hero .title {
    font-size: 32px;
  }
}

.hero .text {
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  opacity: 0.75;
  white-space: pre-wrap;
}

.hero.alternate .title {
  color: #001940;
}

.hero.alternate .text {
  color: #001940;
  text-align: left;
}

.hero .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 32px;
  padding: 32px;
  font-size: 16px;
}

.hero .grid .label {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 4px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 124px;
  width: 100%;
  padding: 32px;
  padding-bottom: max(env(safe-area-inset-bottom), 32px);
}

@media screen and (max-width: 768px) {
  .container {
    padding: var(--gutter-regular);
  }
}

.container .group {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.container .group > .title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.container .group .title .text {
  font-family: var(--font-family-logo);
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  background: linear-gradient(-90deg, #b275ff 0%, #8e9ff8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@media screen and (max-width: 1140px) {
  .container .group > .title {
    flex-direction: column;
    gap: 8px;
  }

  .container .group .title .text {
    text-align: center;
    line-height: 1.5;
  }
}

.container .group .content {
  display: flex;
  align-items: stretch;
  padding-left: 15px;
  gap: 32px;
}

@media screen and (max-width: 1140px) {
  .container .group .content {
    padding-left: 0px;
  }
}

.container .group .content .line {
  width: 2px;
  background: linear-gradient(0deg, transparent 0%, #8e9ff8 100%);
}

@media screen and (max-width: 1140px) {
  .container .group .content .line {
    display: none;
  }
}

.container .group .content .sections {
  display: flex;
  flex-direction: column;
  gap: 128px;
}

@media screen and (max-width: 1140px) {
  .container .group .content .sections {
    gap: 64px;
  }
}

.container .group .content .sections .section .imageAndText {
  display: flex;
  align-items: center;
  gap: 64px;
}

.container .group .content .sections .section span {
  font-family: var(--font-family-logo);
  font-weight: 900;
}

.container .group .content .sections .section .imageAndText.alternate {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1140px) {
  .container .group .content .sections .section .imageAndText.alternate,
  .container .group .content .sections .section .imageAndText {
    flex-direction: column;
    gap: 32px;
  }
}

.container .group .content .sections .section .imageAndText .image {
  position: relative;
  width: 320px;
  aspect-ratio: 1 / 1;
}

.container .group .content .sections .section .imageAndText .image::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  overflow: hidden;
  background: linear-gradient(315deg, transparent 0%, #8e9ff8 100%);
  border-radius: 16px;
}

.container .group .content .sections .section .imageAndText.alternate .image::before {
  right: auto;
  left: 0;
  background: linear-gradient(45deg, transparent 0%, #8e9ff8 100%);
  opacity: 0.5;
}

.container .group .content .sections .section .imageAndText .image .inner {
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 1;
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  box-shadow: var(--shadow-regular);
  border-radius: 16px;
  overflow: hidden;
}

.container .group .content .sections .section .imageAndText .image img {
  margin-top: -1px;
  margin-left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.container .group .content .sections .section .imageAndText.alternate .inner {
  left: auto;
  right: 0;
}

.container .group .content .sections .section .imageAndText .text {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  color: var(--color-text-primary);
  flex: 1;
}

.container .group .content .sections .section .cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.container .group .content .sections .section .cards .title {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: var(--color-text-secondary);
}

@media screen and (max-width: 1140px) {
  .container .group .content .sections .section .cards .title {
    text-align: center;
  }
}

.container .group .content .sections .section .cards .items {
  display: flex;
  align-items: stretch;
  gap: 32px;
}

@media screen and (max-width: 1140px) {
  .container .group .content .sections .section .cards .items {
    align-items: flex-start;
    flex-direction: column;
  }
}

.container .group .content .sections .section .cards .items .card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 16px;
  gap: 8px;
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  box-shadow: var(--shadow-regular);
  border-radius: 8px;
}

.container .group .content .sections .section .cards .items .card .title {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: var(--color-text-secondary);
}

.container .group .content .sections .section .cards .items .card .text {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: var(--color-text-primary);
}

@media screen and (max-width: 1140px) {
  .container .group .content .sections .section .cards .items .card .text {
    text-align: left;
  }
}

.footer {
  padding: 32px 0;
  background-color: #061A3C;
  margin-top: -64px;
  padding-left: 15px;
  color: #ffffff;
}

.footer .group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 64px;
}

@media screen and (max-width: 768px) {
  .footer .group {
    grid-template-columns: 1fr;
  }
}

.footer .group .section {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.video {
  border-radius: 16px;
  overflow: hidden;
}

.footer .group .sectionTitle {
  font-size: 18px;
  font-weight: 800;
}

.footer .group .sectionContent {
  font-size: 14px;
}

.footer .group .sectionContent .links {
  display: grid;
  gap: 8px;
}

.footer .group .sectionContent .link {
  cursor: pointer;
  opacity: 0.75;
  transition: opacity 0.2s ease-in-out;
}

.footer .group .sectionContent .link:hover {
  opacity: 1;
}

.footer .group .sectionContent .contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer .group .sectionContent .contact {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer .group .sectionContent .contact .label {
  font-weight: 800;
  opacity: 0.75;
}

.footer .group .sectionContent .contact .value {
  line-height: 24px;
}