.page {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  padding: var(--gutter-regular);
  padding-bottom: max(env(safe-area-inset-bottom), var(--gutter-regular));
  overflow-x: hidden;
  overflow-y: auto;
}
