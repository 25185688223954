.divider {
  display: flex;
  align-items: center;
  margin: var(--gutter-regular) 0px;
}

.line {
  flex: 1;
  height: 1px;
  background-color: var(--color-border-secondary);
}

.children {
  color: var(--color-text-secondary);
  margin: 0px var(--gutter-regular);
}