.company {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-regular);
}

.company textarea {
  resize: vertical;
  min-height: var(--gutter-large);
}

.company input,
.company textarea {
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  padding: var(--gutter-xsmall);
}

.company input:hover,
.company textarea:hover {
  border-color: var(--color-border-accent);
}

.company input:focus,
.company textarea:focus {
  border-color: var(--color-border-accent);
}

.company {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-regular);
}

.company > .cover {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  border-radius: 5px;
}

.company > .cover .overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-overlay);
}

.company > .cover .input {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  --file-input-cursor: pointer;
  --file-input-field-height: 100%;
  opacity: 0;
}

.company > .header {
  display: flex;
  align-items: center;
  gap: var(--gutter-regular);
}

@media screen and (max-width: 1140px) {
  .company > .header {
    flex-direction: column;
    gap: var(--gutter-regular);
  }
}

.company > .header .avatar {
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-secondary);
  border-radius: 100%;
}

.company > .header .avatar .overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-overlay);
  opacity: 1;
}

.company > .header .avatar .input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  --file-input-cursor: pointer;
  --file-input-field-height: 100%;
  opacity: 0;
}

.company > .header > .divider {
  height: 24px;
  width: 1px;
  min-width: 1px;
  background: var(--color-border-secondary);
  border-radius: 1px;
}

@media screen and (max-width: 1140px) {
  .company > .header > .divider {
    width: 100%;
    height: 1px;
  }
}

.company > .header .name {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
}

.company > .header .stats {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
}

.company > .header .stats .divider {
  height: var(--gutter-regular);
  width: 1px;
  min-width: 1px;
  margin: 0 var(--gutter-regular);
  background: var(--color-border-secondary);
  border-radius: 1px;
}

.company > .header .stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.company > .header .stats .item:first-child {
  align-items: flex-end;
}

.company > .header .stats .item:last-child {
  align-items: flex-start;
}

.company > .header .stats .item .value {
  font-weight: var(--font-weight-medium);
}

.company > .header .stats .item .label {
  font-size: var(--font-size-small);
}

.company > .header .stats .divider {
  height: var(--gutter-regular);
  width: 1px;
}

.company > .header .actions {
  display: flex;
  align-items: center;
  gap: var(--gutter-regular);
  margin-left: auto;
}

@media screen and (max-width: 1140px) {
  .company > .header .actions {
    margin-left: 0px;
  }
}

.company > .badges {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--gutter-regular);
}

@media screen and (max-width: 1140px) {
  .company > .badges {
    justify-content: center;
  }
}

.company > .badges .badge {
  display: flex;
  align-items: center;
  gap: var(--gutter-xsmall);
  height: 40px;
  padding: 0 calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
}

.company > .badges .badge .label {
  color: var(--color-text-secondary);
}

.company > .badges .badge .label::after {
  content: ':';
}

.company > .badges .badge .value {
  color: var(--color-text-primary);
}

.company > .cards {
  display: flex;
  align-items: stretch;
  gap: var(--gutter-regular);
}

@media screen and (max-width: 768px) {
  .company > .cards {
    flex-direction: column;
    align-items: flex-start;
  }
}

.company > .cards .card {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--gutter-small);
  padding: calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
}

@media screen and (max-width: 768px) {
  .company > .cards .card {
    width: 100%;
  }
}

.company > .cards .card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: var(--color-text-button);
  background: var(--color-background-accent);
  border-radius: 100%;
}

.company > .cards .card .title {
  color: var(--color-text-primary);
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  line-height: 2em;
}

.company > .cards .card .text {
  font-size: var(--font-size-medium);
  line-height: 2em;
}

.company > .section {
  display: flex;
  flex-direction: column;
  gap: var(--gutter-small);
}

.company > .section .title {
  font-size: var(--font-size-medium);
  font-weight: var(--font-weight-medium);
  color: var(--color-text-primary);
}

.company > .section .text {
  color: var(--color-text-secondary);
  font-size: var(--font-size-medium);
  line-height: 2em;
}

.company > .section .images {
  display: flex;
  gap: var(--gutter-regular);
  margin-top: var(--gutter-small);
  overflow-y: auto;
}

.company > .section .image {
  width: 256px;
  height: 128px;
  min-width: 256px;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-regular);
}

.company > .section .image .overlay {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-background-overlay);
}

.company > .section .image .input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  --file-input-cursor: pointer;
  --file-input-field-height: 100%;
  opacity: 0;
}

/*.company > .header {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  gap: var(--gutter-regular);*/
/*}*/

/*.company > .header .avatar {*/
/*  width: 96px;*/
/*  height: 96px;*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*  border: 1px solid var(--color-border-secondary);*/
/*  background-color: var(--color-background-secondary);*/
/*  border-radius: 100%;*/
/*}*/

/*.company > .header .details {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  flex-direction: column;*/
/*  gap: var(--gutter-regular);*/
/*}*/

/*.company > .header .name {*/
/*  font-size: var(--font-size-medium);*/
/*}*/

/*.company > .header .stats {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  height: 40px;*/
/*  width: 100%;*/
/*  max-width: 240px;*/
/*  padding: 0 calc(var(--gutter-regular) - 1px);*/
/*  background-color: var(--color-background-primary);*/
/*  border: 1px solid var(--color-border-secondary);*/
/*  border-radius: var(--border-radius-regular);*/
/*  color: var(--color-text-secondary);*/
/*  box-shadow: var(--shadow-regular);*/
/*}*/

/*.company > .header .stats .divider {*/
/*  height: var(--gutter-regular);*/
/*  width: 1px;*/
/*  min-width: 1px;*/
/*  margin: 0 var(--gutter-regular);*/
/*  background: var(--color-border-secondary);*/
/*  border-radius: 1px;*/
/*}*/

/*.company > .header .stats .item {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  flex: 1;*/
/*}*/

/*.company > .header .stats .item:first-child {*/
/*  align-items: flex-end;*/
/*}*/

/*.company > .header .stats .item:last-child {*/
/*  align-items: flex-start;*/
/*}*/

/*.company > .header .stats .item .value {*/
/*  font-weight: var(--font-weight-medium);*/
/*}*/

/*.company > .header .stats .item .label {*/
/*  font-size: var(--font-size-small);*/
/*}*/

/*.company > .header .stats .divider {*/
/*  height: var(--gutter-regular);*/
/*  width: 1px;*/
/*}*/

/*.company > .header .description {*/
/*  width: 100%;*/
/*  max-width: 640px;*/
/*  text-align: center;*/
/*  line-height: 20px;*/
/*  color: var(--color-text-secondary);*/
/*}*/

/*.company .actions {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  gap: var(--gutter-regular);*/
/*}*/
