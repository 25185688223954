.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  background-color: var(--loader-background-color, var(--color-background-secondary));
  transition: opacity var(--transition);
}

.container.active {
  opacity: 1;
  pointer-events: all;
}