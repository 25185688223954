.container {
  --size: var(--spinner-size, 24px);

  position: relative;
  width: var(--size);
  height: var(--size);
  animation: rotation 1s infinite;
}

.shape {
  position: absolute;
  width: calc(var(--size) / 3);
  height: calc(var(--size) / 3);
  background-color: var(--spinner-color, var(--color-text-disabled));
  border-radius: 100%;
}

.shape[data-shape="1"] {
  left: 0;
  animation: shape1 0.5s infinite alternate;
}

.shape[data-shape="2"] {
  right: 0;
  animation: shape2 0.5s infinite alternate;
}

.shape[data-shape="3"] {
  bottom: 0;
  animation: shape3 0.5s infinite alternate;
}

.shape[data-shape="4"] {
  bottom: 0;
  right: 0;
  animation: shape4 0.5s infinite alternate;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes shape1 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(var(--spinner-size) / 1.5), calc(var(--spinner-size) / 1.5));
  }
}

@keyframes shape2 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(var(--spinner-size) / 1.5 * -1), calc(var(--spinner-size) / 1.5));
  }
}

@keyframes shape3 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(var(--spinner-size) / 1.5), calc(var(--spinner-size) / 1.5 * -1));
  }
}

@keyframes shape4 {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(calc(var(--spinner-size) / 1.5 * -1), calc(var(--spinner-size) / 1.5 * -1));
  }
}