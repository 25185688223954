.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  height: 100%;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--color-background-secondary);
}

.sidebar {
  display: grid;
  grid-template-rows: auto 1px auto 1px auto 1fr auto;
  height: 100%;
  overflow: hidden;
  width: 240px;
  background-color: var(--color-background-primary);
  box-shadow: var(--shadow-regular);
  border-right: 1px solid var(--color-border-secondary);
  transition: width var(--transition);
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-template-columns: 1fr;
  }

  .sidebar {
    position: absolute;
    left: 0;
    top: 73px;
    width: 100%;
    height: calc(100% - 73px);
    z-index: 99999;
    opacity: 0;
    grid-template-rows: auto 1px auto 1fr auto;
    pointer-events: none;
    border-right: 0;
    transition: opacity var(--transition);
  }

  .sidebar.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar > * {
  width: 100%;
  overflow: hidden;
}

.sidebar .header {
  padding: var(--gutter-regular);
}

@media screen and (max-width: 768px) {
  .sidebar .header {
    display: none;
  }

  .sidebar .header + .divider {
    display: none;
  }
}

.sidebar .header .logo {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--gutter-small);
  transition: padding var(--transition);
}

.sidebar.collapsed .header .logo {
  padding-left: var(--gutter-small);
}

.sidebar .header .logo .image {
  min-width: 32px;
}

.sidebar .header .logo .text {
  font-family: var(--font-family-logo);
  font-weight: 900;
  font-size: var(--font-size-large);
  transition: opacity var(--transition);
}

.sidebar.collapsed .header .logo .text {
  opacity: 0;
}

.sidebar .divider {
  height: 1px;
  width: calc(100% - (var(--gutter-regular) * 2));
  margin: 0 var(--gutter-regular);
  background: var(--color-border-secondary);
}

.sidebar .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--gutter-regular);
  gap: var(--gutter-regular);
}

.sidebar .profile .avatar {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-secondary);
  border-radius: 100%;
}

.sidebar .profile .name {
  font-size: var(--font-size-medium);
}

.sidebar .profile .stats {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 calc(var(--gutter-regular) - 1px);
  background-color: var(--color-background-primary);
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  color: var(--color-text-secondary);
  box-shadow: var(--shadow-regular);
}

.sidebar .profile .stats.divider {
  height: var(--gutter-regular);
  width: 1px;
  border-radius: 1px;
}

.sidebar .profile .stats .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.sidebar .profile .stats .item:first-child {
  align-items: flex-end;
}

.sidebar .profile .stats .item:last-child {
  align-items: flex-start;
}

.sidebar .profile .stats .item .value {
  font-weight: var(--font-weight-medium);
}

.sidebar .profile .stats .item .label {
  font-size: var(--font-size-small);
}

.sidebar .profile .stats .divider {
  height: var(--gutter-regular);
  width: 1px;
}

.sidebar .createPost {
  padding: var(--gutter-regular);
}

.sidebar .createPost .button {
  width: 100%;
}

.sidebar .nav {
  position: relative;
}

.sidebar .nav .inner {
  height: 100%;
  overflow-y: auto;
}

.sidebar .nav::before, .sidebar .nav::after {
  content: '';
  position: absolute;
  left: var(--gutter-regular);
  top: 0;
  width: calc(100% - (var(--gutter-regular) * 2));
  height: 1px;
  background-color: var(--color-border-secondary);
}

.sidebar .nav::after {
  top: auto;
  bottom: 0;
}

.sidebar .nav .content {
  padding: var(--gutter-regular);
}

.sidebar .logout {
  width: 100%;
  padding: var(--gutter-regular);
}

.sidebar .logout .button {
  width: 100%;
}

.main {
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .main {
    display: grid;
    grid-template-rows: auto 1fr;
  }
}

.main .header {
  display: none;
  align-items: center;
  justify-content: space-between;
  gap: var(--gutter-regular);
  padding: var(--gutter-regular);
  background-color: var(--color-background-primary);
  border-bottom: 1px solid var(--color-border-secondary);
  box-shadow: var(--shadow-regular);
}

@media screen and (max-width: 768px) {
  .main .header {
    display: flex;
  }
}

.main .header .divider {
  height: var(--gutter-large);
  width: 1px;
  min-width: 1px;
  max-width: 1px;
  background: var(--color-border-secondary);
}

.main .header .logo {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--gutter-small);
}

.main .header .logo .image {
  min-width: 32px;
}

.main .header .logo .text {
  font-family: var(--font-family-logo);
  font-weight: 900;
  font-size: var(--font-size-large);
  transition: opacity var(--transition);
}

.main.collapsed .header .logo .text {
  opacity: 0;
}

.main .pageEnter {
  opacity: 0;
}

.main .pageEnterActive {
  opacity: 1;
  transition: opacity var(--transition), transform var(--transition);
}

.main .pageExit {
  opacity: 1;
}

.main .pageExitActive {
  opacity: 0;
  transition: opacity var(--transition), transform var(--transition);
}

.ad {
  padding: var(--gutter-regular);
}

.ad img {
  border-radius: var(--border-radius-regular);
}