.navigation {
  overflow: hidden;
}

.list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--gutter-small);
}

.item {
  width: 100%;
}

.link {
  outline: none;
  text-decoration: none;
}

.button {
  display: block;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  gap: var(--gutter-regular);
  padding: 0 var(--gutter-regular);
  color: var(--color-text-secondary);
  border: 1px solid transparent;
  border-radius: var(--border-radius-regular);
  transition: color var(--transition), border-color var(--transition),
    background-color var(--transition), box-shadow var(--transition);
}

.button:hover, .button:focus {
  color: var(--color-text-accent);
}

.link:focus .button {
  color: var(--color-text-accent);
}

.item .label {
  font-weight: var(--font-weight-regular);
}

.divider {
  width: 1px;
  height: var(--gutter-regular);
  background-color: var(--color-border-secondary);
}

.label {
  flex: 1;
  text-align: left;
  overflow: hidden;
  transition: padding var(--transition);
}

.collapsed .label {
  opacity: 0;
}

.toggle {
  transition: padding var(--transition);
}

.collapsed .label {
  opacity: 0;
}

.children {
  display: flex;
  align-items: stretch;
  width: 100%;
  padding-left: calc(var(--gutter-regular) + var(--gutter-small));
  overflow: hidden;
  opacity: 0;
  transition: height var(--transition), opacity var(--transition);

}

.children .line {
  width: 1px;
  background-color: var(--color-border-secondary);
}

.children .list {
  padding-left: var(--gutter-small);
}

.item.expanded .children {
  opacity: 1;
}

.item.active > .link .button,
.item.containsActive:not(.expanded) > .button,
.navigation.collapsed .item.containsActive > .button {
  color: var(--color-text-accent);
  background-color: var(--color-border-accent-lighter);
}