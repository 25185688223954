.card {
  display: flex;
  flex-direction: column;
}

.card .header {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--gutter-regular);
  margin-bottom: var(--gutter-regular);
}

.card .header .author {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--gutter-regular);
}

.card .header .avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-secondary);
}

.card .coverContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  margin-bottom: var(--gutter-regular);
}

.card .coverContainer .cover {
  display: flex;
  width: 100%;
}

.card .coverContainer .readMore {
  position: absolute;
  right: var(--gutter-regular);
  bottom: var(--gutter-regular);
  opacity: 0;
  transform: scale(0);
  transition: opacity var(--transition), transform var(--transition);
}

@media screen and (max-width: 768px) {
  .card .coverContainer .readMore {
    opacity: 1 !important;
    transform: none !important;
  }
}

.card:hover .coverContainer .readMore {
  opacity: 1;
  transform: none;
}

.card .actions {
  display: flex;
  gap: var(--gutter-regular);
  margin-bottom: var(--gutter-regular);
}

.card .actions .bookmark {
  margin-left: auto;
}

.card .title {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--gutter-small);
}

.card .content {
  position: relative;
  line-height: 24px;
  max-height: 124px;
  overflow: hidden;
}

.card .content::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(0deg, var(--color-background-primary) 0%, rgba(0, 0, 0, 0) 100%);
}

.modal .body {
  display: flex;
  flex-direction: column;
  /* gap: var(--gutter-regular); */
}

.modal .body .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--gutter-regular);
}

.modal .author {
  display: flex;
  align-items: center;
  height: 40px;
  gap: var(--gutter-regular);
}

.modal .author .avatar {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-size: cover;
  background-position: center;
  border: 1px solid var(--color-border-secondary);
  background-color: var(--color-background-secondary);
}

.modal .coverContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid var(--color-border-secondary);
  border-radius: var(--border-radius-regular);
  margin-bottom: var(--gutter-regular);
}

.modal .coverContainer .cover {
  display: flex;
  width: 100%;
}

.modal .coverContainer .readMore {
  position: absolute;
  right: var(--gutter-regular);
  bottom: var(--gutter-regular);
  opacity: 0;
  transform: scale(0);
  transition: opacity var(--transition), transform var(--transition);
}

.modal:hover .coverContainer .readMore {
  opacity: 1;
  transform: none;
}

.modal .actions {
  display: flex;
  gap: var(--gutter-regular);
  margin-bottom: var(--gutter-regular);
}

.modal .actions .bookmark {
  margin-left: auto;
}

.modal .title {
  font-size: 24px;
  line-height: 36px;
  font-weight: var(--font-weight-medium);
  margin-bottom: var(--gutter-small);
}

.modal .content {
  position: relative;
  font-size: 16px;
  line-height: 32px;
  white-space: pre-wrap;
}
